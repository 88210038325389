import React from "react";
import Putin from "./photo/putin.jpg"
import Belousov from "./photo/belousov.jpg"
import Lapin from "./photo/lapin.jpg"
import Gerasimov from "./photo/gerasimov.jpg"

function Command()
{
    return(
        <div className="commandBody">
            <div className="underHeader"></div>
            <h2>Командование</h2>
            <div className="commandList">
                <div className="commandItem">
                    <div className="commandPhoto">
                        <img src={Putin} alt="Президент РФ"/>
                    </div>
                    <div className="commandDescrip">
                        <h3 className="commandName">Владимир Владимирович Путин</h3>
                        <p>Президент Российской Федерации – Верховный Главнокомандующий Вооруженными Силами Российской Федерации.</p>
                        <h4>Награды</h4>
                        <div className="medal">
                            <p>Орден Почёта (12 марта 1996) — за заслуги перед государством и большой вклад в обустройство таможенной границы с государствами Прибалтики;</p>
                            <p>Благодарность Президента Российской Федерации (30 марта 1998) — за активное участие в подготовке Послания Президента Российской Федерации Федеральному Собранию 1998 года;</p>
                            <p>Благодарность Президента Российской Федерации (22 февраля 1999) — за большой вклад в укрепление обороноспособности страны и в связи с Днём защитников Отечества;</p>
                            <p>Благодарность Президента Российской Федерации (30 июля 1999) — за активное участие в реализации плана политического урегулирования конфликта между Союзной Республикой Югославией и НАТО и оказании гуманитарной помощи населению Союзной Республики Югославии;</p>
                            <p>Именной морской палаш от командующего Черноморского флота России адмирала Владимира Комоедова и командующего Военно-морскими силами Украины Михаила Ежеля (2000);</p>
                            <p>Памятная медаль А. М. Горчакова (МИД РФ, январь 2001);</p>
                            <p>Орден Ахмата Кадырова (Чечня, 2007, вручён в 2023 году);</p>
                            <p>Орден «За заслуги перед Республикой Дагестан» (13 сентября 2014) — за большой личный вклад в обеспечение защиты конституционного строя и в связи с 15-летием разгрома международных террористов, вторгшихся на территорию Республики Дагестан.</p>
                        </div>
                    </div>
                </div>
                <div className="commandItem">
                    <div className="commandPhoto">
                            <img src={Belousov} alt="Министро обороны РФ"/>
                    </div>
                    <div className="commandDescrip">
                        <h3 className="commandName">Андрей Рэмович Белоусов</h3>
                        <p>Министр обороны Российской Федерации.</p>
                        <h4>Награды</h4>
                        <div className="medal">
                            <p>Медаль «В память 850-летия Москвы» (1997);</p>
                            <p>Благодарность Президента Российской Федерации — за заслуги в обеспечении деятельности Государственного совета Российской Федерации (1 сентября 2005);</p>
                            <p>Заслуженный экономист Российской Федерации (12 июня 2007) — за заслуги в проведении экономической политики государства и многолетнюю добросовестную работу;</p>
                            <p>Орден Почёта (14 июня 2009) — за достигнутые трудовые успехи и добросовестную многолетнюю работу;</p>
                            <p>Медаль «100 лет Военно-воздушным силам» (2012);</p>
                            <p>Медаль «За вклад в развитие Евразийского экономического союза» (29 мая 2019, Высший Евразийский экономический совет).</p>
                        </div>
                          </div>
                </div>
                <div className="commandItem">
                    <div className="commandPhoto">
                        <img src={Gerasimov} alt="Первый заместитель Министра обороны РФ"/>
                    </div>
                    <div className="commandDescrip">
                        <h3 className="commandName">Валерий Васильевич Герасимов</h3>
                        <p>Начальник Генерального штаба Вооруженных Сил Российской Федерации, первый заместитель Министра обороны Российской Федерации, генерал армии.</p>
                        <h4>Награды</h4>
                        <div className="medal commandShortP">
                            <p>Герой Российской Федерации (2016);</p>
                            <p>Орден Святого Георгия III степени (2017);</p>
                            <p>Орден Святого Георгия IV степени (2015);</p>
                            <p>Орден «За заслуги перед Отечеством» I степени (2021);</p>
                            <p>Орден «За заслуги перед Отечеством» III степени с мечами (2014);</p>
                            <p>Орден «За заслуги перед Отечеством» IV степени с мечами (2002);</p>
                            <p>Орден Александра Невского (2020);</p>
                            <p>Орден «За военные заслуги» (2000);</p>
                            <p>Орден Почёта (2012);</p>
                            <p>Орден «За службу Родине в Вооружённых Силах СССР» III степени;</p>
                            <p>Медаль «За боевые заслуги»;</p>
                            <p>Медаль «В память 1000-летия Казани»;</p>
                            <p>Медаль «60 лет Вооружённых Сил СССР»;</p>
                            <p>Медаль «70 лет Вооружённых Сил СССР»;</p>
                            <p>Медаль «За боевые отличия»;</p>
                            <p>Медаль «За воинскую доблесть» 1-й степени;</p>
                            <p>Медаль «За укрепление боевого содружества»;</p>
                            <p>Медаль «200 лет Министерству обороны»;</p>
                            <p>Медаль «За отличие в военной службе» 1-й степени;</p>
                            <p>Медаль «За безупречную службу» 2-й степени;</p>
                            <p>Медаль «За безупречную службу» 3-й степени;</p>
                            <p>Медаль «За участие в военном параде в День Победы»;</p>
                            <p>Медаль «За возвращение Крыма»;</p>
                            <p>Медаль «За морские заслуги в Арктике»;</p>
                            <p>Медаль «За укрепление государственной системы защиты информации» 1-й степени;</p>
                            <p>Медаль «За заслуги в обеспечении национальной безопасности»;</p>
                            <p>Медаль «За содружество во имя спасения»;</p>
                            <p>Заслуженный военный специалист Российской Федерации (2009).</p>
                        </div>
                    </div>
                </div>
                <div className="commandItem">
                    <div className="commandPhoto">
                        <img src={Lapin} width="100%" height="auto" alt="Командующий ЛВО"/>
                    </div>
                    <div className="commandDescrip">
                        <h3 className="commandName">Александр Павлович Лапин</h3>
                        <p>Командующий войсками Ленинградского военного округа.</p>
                        <h4>Награды</h4>
                        <div className="medal commandShortP">
                            <p>Герой Российской Федерации (2022)</p>
                            <p>Орден Святого Георгия IV степени;</p>
                            <p>Орден «За заслуги перед Отечеством» IV степени с мечами;</p>
                            <p>Орден Александра Невского;</p>
                            <p>Орден Мужества;</p>
                            <p>Орден «За военные заслуги»;</p>
                            <p>Медаль ордена «За заслуги перед Отечеством» II степени;</p>
                            <p>Медаль «За боевые отличия»;</p>
                            <p>Медаль «Участнику военной операции в Сирии»;</p>
                            <p>Медаль «За освобождение Пальмиры»;</p>
                            <p>Медаль «Боевое содружество» (Сирия);</p>
                            <p>Медаль «За заслуги перед Казачеством» 2 степени;</p>
                            <p>Терский казачий знак «Генерал Ермолов»;</p>
                            <p>Медаль «За заслуги в борьбе с международным терроризмом».</p>
                        </div>                        
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Command;