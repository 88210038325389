function ParalaxBlock()
{
    return (
        <>
        <div className="prlxWrapper">
            <div className="prlxBody">
                <h1>Враг будет повержен</h1>
            </div>
        </div>
            
        </>
    )
}

export default ParalaxBlock;